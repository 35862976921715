*,
*:before,
*:after {
  box-sizing: border-box;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  max-width: 1200px;

  @include breakpoint($mobile-bp) {
    max-width: 800px;
    max-width: 800px;
    padding: 15px;
  }

  @include breakpoint($tablet-bp) {
    padding: 35px;
  }

  @include breakpoint($big-screen-bp) {
    max-width: 1200px;
    padding: 15px;
  }
}

body {
  font-family: "Roboto", sans-serif;
  margin: 0;
  line-height: 1.5;
  background-color: $primary;
  color: $white-txt;
}

//button
.button {
  background-color: $gray;
  padding: 17px 27px;
  border: none;
  color: inherit;
  border-radius: 14px;
  font-size: 18px;
  font-weight: 600;

  @include breakpoint($tablet-bp) {
    font-size: 18px;
    padding: 22px 35px;
  }

  @include breakpoint($mobile-bp) {
    font-size: 16px;
    padding: 20px 30px;
  }

  &:hover {
    border: 1px solid $accent;
    padding: 16px 26px;
    @include breakpoint($tablet-bp) {
      padding: 21px 34px;
      @include breakpoint($mobile-bp) {
        padding: 19px 29px;
      }
    }
  }
}

//Header
.header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
  align-items: center;
  border-bottom: 2px solid $gray;
  z-index: 1000;

  @include breakpoint($mobile-bp) {
    position: fixed;
    background-color: $primary;
    width: 100%;
    top: 0;
    padding: 20px;
    flex-wrap: wrap;
  }

  &-return {
    font-size: 18px;
    margin: 8px 0;
    color: $white-txt;
    text-decoration: underline;
    &:hover {
      color: $accent;
    }
    &img {
      color: $white-txt;
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    align-items: center;
    &-title {
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
      font-size: 40px;
      font-weight: 600;
      line-height: normal;
      color: $accent;
      margin-left: 20px;

      @include breakpoint($mobile-bp) {
        font-size: 28px;
      }
      @include breakpoint($tablet-bp) {
        font-size: 30px;
      }
      @include breakpoint($small-mobile-bp) {
        font-size: 24px;
      }

      img {
        width: 20px;
      }
    }
  }
  & > .button {
    @include breakpoint($mobile-bp) {
      padding: 14px 20px;
      font-size: 16px;
    }
  }
}

//About
.about {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 55px;
  @include breakpoint($tablet-bp) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @include breakpoint($mobile-bp) {
    margin-top: 140px;
  }
  & img {
    width: 500px;
    height: auto;
    margin: 0 auto;
    @include breakpoint($mobile-bp) {
      width: 450px;
    }
    @include breakpoint($small-mobile-bp) {
      width: 300px;
    }
  }

  &-info {
    display: flex;
    flex-direction: column;
    margin-left: 100px;

    @include breakpoint($mobile-bp) {
      margin-left: 0;
    }

    @include breakpoint($mobile-bp) {
      margin-left: 0;
    }

    &-title {
      font-size: 40px;
      font-weight: 700;
      line-height: normal;

      color: $accent;
      margin-bottom: 40px;

      @include breakpoint($tablet-bp) {
        margin-top: 50px;
        font-size: 50px;
        margin-bottom: 20px;
        text-align: center;
      }

      @include breakpoint($mobile-bp) {
        margin-top: 50px;
        font-size: 37px;
        margin-bottom: 20px;
      }
      @include breakpoint($small-mobile-bp) {
        font-size: 30px;
      }
    }
    &-txt {
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 155%;

      &--accent {
        color: $accent;
      }

      @include breakpoint($small-mobile-bp) {
        font-size: 18px;
      }
    }
  }

  // slider
  .slick-dots {
    top: -40px;

    & li {
      &.slick-active {
        & button::before {
          color: $white-txt;
          opacity: 1;
        }
      }
    }
  }

  .slick-dots {
    & li {
      & button::before {
        color: $white-txt;
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }
}

//Slider
.slick-slider {
  max-width: 100%;
  width: 500px;
  & > button {
    display: none;
  }
}

.slider-container {
  max-width: 100%;
  width: 600px;
  margin: 0 auto;
}

.slider-image {
  max-width: 100%;
  height: auto;
}

//Catalog
.catalog {
  margin-top: 30px;

  &-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    justify-content: space-around;
    align-items: baseline;
    margin-top: 30px;
  }

  &-nav {
    display: flex;
    justify-content: center;
    margin: 50px 0;

    &-item {
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 600;
      padding: 20px;
      width: 120px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: $gray;
      border: 1px solid $primary;
      border-radius: 3px;
      cursor: pointer;
      text-transform: uppercase;
      transition: background-color 0.5s ease-in-out;

      &.active {
        background-color: $accent;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      }

      @include breakpoint($mobile-bp) {
        font-size: 12px;
        width: 80px;
        height: 80px;
        padding: 32px 0;
      }
    }
  }

  &-item {
    padding: 30px;
    border-radius: 14px;
    margin-bottom: 30px;
    max-width: 370px;

    transition: all 1s ease-in-out;
    cursor: pointer;
    &-image {
      height: auto;
      display: block;
      margin-bottom: 30px;
    }

    &-title {
      text-transform: uppercase;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include breakpoint($mobile-bp) {
        font-size: 18px;
      }
    }

    &-price {
      color: $accent;
      margin: 10px 0 25px 0;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @include breakpoint($mobile-bp) {
        font-size: 22px;
      }
    }
  }
}
//footer

.footer {
  color: $white-txt;
  width: 100%;
  padding: 15px 0 5px 0;
  border-top: 1px solid $gray;
  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &-social {
      display: flex;
      justify-content: space-between;
      & li {
        margin-right: 25px;
      }
    }
  }
  &-logo {
    display: flex;
    align-items: center;
    & > img {
      color: $white-txt;
    }
  }

  &-title {
    color: $accent;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    font-size: 26px;
    font-weight: 400;
    margin-left: 15px;
  }
}

//product

.product {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  &-about {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & img {
      width: 480px;
      margin: 0 auto;
      @include breakpoint($mobile-bp) {
        width: 320px;
      }
      @include breakpoint($small-mobile-bp) {
        width: 220px;
      }
    }
    @include breakpoint($tablet-bp) {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 40px;
    }
    &-info {
      margin-left: 150px;
      @include breakpoint($tablet-bp) {
        margin-left: 0;
        margin-top: 20px;
      }
      & h2 {
        font-size: 40px;
        @include breakpoint($mobile-bp) {
          font-size: 30px;
        }
      }
      &-dscrpt {
        opacity: 0.8;
        font-size: 20px;
        font-style: italic;
        line-height: 1.35;
        margin: 15px 0;
        @include breakpoint($mobile-bp) {
          font-size: 18px;
        }
      }

      &-price {
        color: $accent;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 40px;
        @include breakpoint($mobile-bp) {
          font-size: 30px;
        }
      }
    }
  }
  &-details {
    border-top: 1px $gray solid;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-top: 20px;

    &-item {
      break-inside: avoid;

      &-title {
        color: $accent;
        font-weight: 600;
        font-size: 25px;
        @include breakpoint($mobile-bp) {
          font-size: 22px;
        }
      }
      &-text {
        font-weight: 600;
        font-size: 20px;
        @include breakpoint($mobile-bp) {
          font-size: 18px;
        }
      }
    }
  }
  .slick-arrow {
    z-index: 1000;
    &::before {
      color: $accent;
      background-color: none;
      font-size: 30px;
      @include breakpoint($mobile-bp) {
        font-size: 28px;
      }
    }
  }
  .slick-prev {
    @include breakpoint($tablet-bp) {
      left: -1px;
    }
  }

  .slick-next {
    @include breakpoint($tablet-bp) {
      right: 0;
    }
  }
}
